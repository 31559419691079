import { Routes, Route} from 'react-router-dom'
import MediaQuery from 'react-responsive'
import { AnimatePresence } from 'framer-motion';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';

// Desktop
import Home from './Pages/Home';
import Menu from './Pages/Menu';
import Petra from './Pages/Petra';
import Contact from './Pages/Contact';
import Gift from './Pages/Gift';
import Test from './Pages/ComingSoon';



// Mobile
import MHome from './Mobile/Home';
import MMenu from './Mobile/Menu';
import MContact from './Mobile/Contact';
import MPetra from './Mobile/Petra';
import MTest from './Mobile/Test';

function App() {

  const { t } = useTranslation()

  return (
    <div>
      <MediaQuery maxDeviceWidth={500}>
        <AnimatePresence exitBeforeEnter>
          <Routes>
            <Route path="/" element={<MHome />}  />
            <Route path="/menu" element={<MMenu />}  />
            <Route path="/menu/:cat" element={<MMenu />}  />
            <Route path="/petra" element={<MPetra />}  />
            <Route path="/contact" element={<MContact />}  />
            <Route path="/test" element={<Test />}  />
          </Routes>
        </AnimatePresence>
      </MediaQuery>

      <MediaQuery minDeviceWidth={501}>
        <AnimatePresence exitBeforeEnter>
          <Routes>
            <Route path="/" element={<Home />}  />
            <Route path="/menu" element={<Menu />}  />
            <Route path="/menu/:cat" element={<Menu />}  />
            <Route path="/petra" element={<Petra />}  />
            {/* <Route path="/gift" element={<Gift />}  /> */}
            <Route path="/contact" element={<Contact />} />
            {/* <Route path="/test" element={<Test />} /> */}

          </Routes>
          </AnimatePresence>
      </MediaQuery>
      <CookieConsent 
          location='bottom' 
          buttonText={t('cookie.perfect')}  
          style={{ background: "black", color:'white', fontSize:'2vh' }} 
          buttonStyle={{ backgroundColor:'#98D1D9', color: 'black', fontSize: '2vh' }}    
          expires={10}>
        {t('cookie.msg')} 
        {/* <span style={{ fontSize: '10px' }}>Hello</span> */}
      </CookieConsent>  
    </div>
  );
}

export default App;
