import React, {useState} from 'react'
import MainMenu from '../components/MainMenu'
import { FilterContainer, FoodContainer } from '../components/styles/Containers.styles'
import { MenuCategory } from '../components/styles/Buttons.style'
import Footer from '../components/Footer'
import { Title } from '../components/styles/Text.styles'
import MenuItem from '../components/MenuItem'
import { useTranslation } from 'react-i18next'
import { Spacer } from '../components/styles/Containers.styles'
import AnimatedPages from '../components/Animations/AnimatedPages'
import { useParams } from 'react-router'
import { useEffect } from 'react'

const Menu = () => {

    const [ category, setCategory] = useState('Carta')

    const {t} = useTranslation()

    const {cat} = useParams()

    const items = [
        {
            src : "./../media/menu/carta_1.jpg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Carta"
        },
        {
            src : "./../media/menu/carta_2.jpg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Carta"
        },
        {
            src : "./../media/menu/carta_3.jpg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Carta"
        },
        {
            src : "./../media/menu/carta_4.jpg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Carta"
        },
        {
            src : "./../media/menu/carta_5.jpg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Carta"
        },
        {
            src : "./../media/menu/carta_6.jpg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Carta"
        },
        {
            src : "./../media/menu/zapping_1.jpeg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Menu Zapping"
        },
        {
            src : "./../media/menu/zapping_2.jpeg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Menu Zapping"
        },
        {
            src : "./../media/menu/zapping_3.jpeg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Menu Zapping"
        },
        {
            src : "./../media/menu/zapping_4.jpeg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Menu Zapping"
        },
        {
            src : "./../media/menu/zapping_5.jpeg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Menu Zapping"
        },
        {
            src : "./../media/menu/zapping_6.jpeg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Menu Zapping"
        },
        {
            src : "./../media/menu/wines/wine_1.jpeg",
            title: "Vino",
            description: "Vino",
            category:"wines"
        },
        {
            src : "./../media/menu/wines/wine_2.jpeg",
            title: "Vino",
            description: "Vino",
            category:"wines"
        },
        {
            src : "./../media/menu/wines/wine_3.jpg",
            title: "Vino",
            description: "Vino",
            category:"wines"
        },
        {
            src : "./../media/menu/wines/wine_4.jpg",
            title: "Vino",
            description: "Vino",
            category:"wines"
        },
        {
            src : "./../media/menu/wines/wine_5.jpeg",
            title: "Vino",
            description: "Vino",
            category:"wines"
        },
        {
            src : "./../media/menu/wines/wine_cava.jpg",
            title: "Vino",
            description: "Vino",
            category:"wines"
        },

    ]

    useEffect(() => {
        if(cat ==='card'){
            setCategory('Carta')
        }
        if(cat ==='wines'){
            setCategory('wines')
        }
        if(cat ==='zapping'){
            setCategory('Menu Zapping')
        }
    },[cat])

    return (
        <AnimatedPages>
            <MainMenu color="black" selected='#98D1D9' selectedD='black' hamburger='black'/>
            <Spacer space='5vh'/>
            <div style={{padding:'5vw', paddingTop:'15vh'}}>
                <Title style={{fontSize:'10vh', marginLeft:'-1vh'}}>{t('card.title')}</Title>
                <Spacer space='5vh'/>
               
                <FilterContainer>
                    <MenuCategory onClick={() => setCategory('Carta')} autoFocus>{t('card.card')}</MenuCategory>
                    <MenuCategory onClick={() => setCategory('Menu Zapping')}>{t('card.zapping')}</MenuCategory>
                    <a href="https://www.instagram.com/restaurantpetra/"><MenuCategory>{t('card.midday')}</MenuCategory></a>
                    {/* <MenuCategory onClick={() => setCategory('wines')}>{t('card.wines')}</MenuCategory> */}
                </FilterContainer>
                
                <div style={{display:'flex', justifyContent:'center'}}>
                    <AnimatedPages>
                        <FoodContainer>
                            {/* {items.filter((item, i ) => filtration(item, i))} */}
                            {/* {category === 'all'  &&  items.map((item, i) => {
                                return <MenuItem key={i} src={item.src} title={item.title} description={item.description} /> 
                            
                            })} */}
                            {items.map((item, i) => {
                                return (category === item.category && <MenuItem key={i} src={item.src} title={item.title} description={item.description} />) 
                            
                            })}

                        </FoodContainer>
                    </AnimatedPages>
                </div>

                <div style={{ width:'100vw', paddingTop:'5vh'}}>
                    {/* <img src={'./../media/menu/carta_petra.jpg'} alt="menu"/> */}
                    <img src={'./../media/menu/Petra_English.jpeg'} alt="menu" width='90%' height='auto'/>
                    <img src={'./../media/menu/Petra_Catalan.jpeg'} alt="menu" width='90%' height='auto'/>
                </div>

            </div>
            <Footer />
            
        </AnimatedPages>
    )
}

export default Menu


    // const filtration = (item, i) => {

    //     console.log("Filtration")
        
    //     if( item.category === 'Carta Del Petra'){
    //         console.log("Carta Del Petra");
    //         <MenuItem key={i} src={item.src} title={item.title} style={{ }} />
    //     }

    //     if(item.category === category){
    //         console.log(category);
    //         <MenuItem key={i} src={item.src} title={item.title} style={{ }} />
    //     }
    // }