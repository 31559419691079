import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'


const Home = () => {

    return (
        <div style={{padding:'5vh', display:'flex', flexDirection:'column', alignItems:'center', gap:'5vh'}}>
            <h3>Restaurant Petra (El Born) Barcelona</h3>

            {/* Catalan */}
            <div style={{textAlign:'center', padding:'0 10vh'}}>
                <div style={{fontSize:'8vh'}}>Pròximament...</div>
                <p style={{fontSize:'3vh'}}>El nostre website s'estrenarà aviat, però mentrestant pots fer una reserva a <b>info@restaurantpetra.com o +34 93 319 99 99</b></p>
                <p style={{fontSize:'3vh'}}>Us esperem al: Carrer dels Sombrerers, 13, 08003 Barcelona</p>
            </div>
            {/* English */}
            <div style={{textAlign:'center', padding:'0 10vh'}}>
                <div style={{fontSize:'8vh'}}>Coming Soon...</div>
                <p style={{fontSize:'3vh'}}>Our website will be launched soon but in the meantime you can make a reservation at <b>info@restaurantpetra.com or call us on +34 93 319 99 99</b></p>
                <p style={{fontSize:'3vh'}}>We are waiting for you at: Carrer dels Sombrerers, 13, 08003 Barcelona</p>
            </div>
            <div>
                <a href='https://www.instagram.com/restaurantpetra/'><img src={window.location.origin + '/media/icons/instagram.png'} alt="" height='30vh' width='auto' /></a>
                <a href='mailto:info@restaurantpetra.com'><img src={window.location.origin + '/media/icons/email.png'} alt="" height='30vh' width='auto' /></a>
            </div>
        </div>
    )
}

export default Home
