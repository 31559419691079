import React, {useState} from 'react'
import Hamburger from '../components/Mob/Hamburger'
import LogoLine from '../components/Mob/LogoLine'
import { FilterContainer, FoodContainer} from '../components/styles/Containers.styles'
import { MenuCategory } from '../components/styles/Buttons.style'
import Footer from '../components/Mob/Footer'
import { MTitle } from '../components/styles/Text.styles'
import MenuItem from '../components/Mob/MenuItem'
import { useTranslation } from 'react-i18next'
import { Spacer } from '../components/styles/Containers.styles'
import AnimatedPages from '../components/Animations/AnimatedPages'
import { useParams } from 'react-router'
import { useEffect } from 'react'


// Things to Do
// 1. Check the animation below the Menu Categories
// 2. The filtration system
// 3. When we click on PLatos de medio dia - ir a instagram

const Menu = () => {

    const [ category, setCategory] = useState('Carta')
    // const [ selected, setSelected] = useState(true)

    const {t} = useTranslation()

    const {cat} = useParams()

    const items = [
        {
            src : "./../media/menu/carta_1.jpg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Carta"
        },
        {
            src : "./../media/menu/carta_2.jpg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Carta"
        },
        {
            src : "./../media/menu/carta_3.jpg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Carta"
        },
        {
            src : "./../media/menu/carta_4.jpg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Carta"
        },
        {
            src : "./../media/menu/carta_5.jpg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Carta"
        },
        {
            src : "./../media/menu/carta_6.jpg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Carta"
        },
        {
            src : "./../media/menu/zapping_1.jpeg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Menu Zapping"
        },
        {
            src : "./../media/menu/zapping_2.jpeg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Menu Zapping"
        },
        {
            src : "./../media/menu/zapping_3.jpeg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Menu Zapping"
        },
        {
            src : "./../media/menu/zapping_4.jpeg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Menu Zapping"
        },
        {
            src : "./../media/menu/zapping_5.jpeg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Menu Zapping"
        },
        {
            src : "./../media/menu/zapping_6.jpeg",
            title: "Red Dish",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            category:"Menu Zapping"
        },

        // {
        //     src : "./media/menu/8.jpg",
        //     title: "Red Dish",
        //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        //     category:"Vinos"
        // },
        // {
        //     src : "./media/menu/9.jpg",
        //     title: "Red Dish",
        //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        //     category:"Vinos"
        // }
    ]

    useEffect(() => {
        if(cat ==='card'){
            setCategory('Carta')
        }
        if(cat ==='wines'){
            setCategory('wines')
        }
        if(cat ==='zapping'){
            setCategory('Menu Zapping')
        }
    },[cat])

    return (
        <AnimatedPages>
             <div style={{position:'fixed', top:'0', right:'2%', zIndex:'5', padding:'1vh', width:'24vw'}}>
                <Hamburger color='black' />
            </div>
            <LogoLine />
            {/* <div style={{marginTop:'7vh'}}>
                <MSplitter color='black' bgColor='black'/>
            </div> */}
            <Spacer space='6vh'/>
            <div style={{padding:'5vw'}}>
                <MTitle >{t('card.title')}</MTitle>
                <Spacer space='5vh'/>
               
                <FilterContainer style={{textAlign:'left'}}>
                    <MenuCategory onClick={() => setCategory('Carta')} autoFocus>{t('card.card')}</MenuCategory>
                    <MenuCategory onClick={() => setCategory('Menu Zapping')}>{t('card.zapping')}</MenuCategory>
                    {/* <br /> */}
                    <a href="https://www.instagram.com/restaurantpetra/"><MenuCategory>{t('card.midday')} &nbsp;</MenuCategory></a>
                    {/* <MenuCategory onClick={() => setCategory('Vinos')}>{t('card.wines')}</MenuCategory> */}
                </FilterContainer>
                
                <div style={{display:'flex', justifyContent:'center'}}>
                    <AnimatedPages>
                        <FoodContainer style={{flexDirection:'column', gap:'5vh'}}>
                            {/* {items.filter((item, i ) => filtration(item, i))} */}
                            {/* {category === 'all'  &&  items.map((item, i) => {
                                return <MenuItem key={i} src={item.src} title={item.title} description={item.description} /> 
                            
                            })} */}
                            {items.map((item, i) => {
                                return (category === item.category && <MenuItem key={i} src={item.src} title={item.title} description={item.description} />) 
                            
                            })}

                        </FoodContainer>
                    </AnimatedPages>
                </div>

                {/* <div style={{width:'100%', paddingTop:'5vh', justifyContent:'center', alignItems:'center', textAlign:'center'}}>
                    <img src={'./../media/menu/carta_petra.jpg'} width='100%' height='auto' />
                </div> */}
                <div style={{ width:'100vw', paddingTop:'5vh'}}>
                    {/* <img src={'./../media/menu/carta_petra.jpg'} alt="menu"/> */}
                    <img src={'./../media/menu/Petra_English.jpeg'} alt="menu" width='90%' height='auto'/>
                    <img src={'./../media/menu/Petra_Catalan.jpeg'} alt="menu" width='90%' height='auto'/>
                </div>

            </div>
            <Footer />
            
        </AnimatedPages>
    )
}

export default Menu


    // const filtration = (item, i) => {

    //     console.log("Filtration")
        
    //     if( item.category === 'Carta Del Petra'){
    //         console.log("Carta Del Petra");
    //         <MenuItem key={i} src={item.src} title={item.title} style={{ }} />
    //     }

    //     if(item.category === category){
    //         console.log(category);
    //         <MenuItem key={i} src={item.src} title={item.title} style={{ }} />
    //     }
    // }